import React, { useEffect } from "react";
import AppBody from "../../../components/body/main.body.component";
import {
   Grid,
   Tabs,
   Tab,
   Button,
   Avatar,
   Menu,
   MenuItem,
   Snackbar,
   Alert,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DataTableComponent } from "../../../components/common/data.table.component";
import { useTheme } from "@mui/material";
import { useState } from "react";
import {
   GridColDef,
   GridRowSelectionModel,
   useGridApiRef,
   GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { ToursPageSearchInput } from "../../../components/tours/tours.search.input";
import { useGradient } from "../../../contexts/gradients/gradients.context";
import {
   useToursState,
   useToursDispatch,
} from "../../../contexts/tours/tours.context";
import { format } from "date-fns-tz";
import { useUserState } from "../../../contexts/user/user.context";
import { fetchTouringAgreementFilesWithBookingIdArray } from "../../../contexts/api/api.functions";
import b64ToBlob from "b64-to-blob";
type Props = {};

//// user photos
//// src={`https://boxlty-media.s3.amazonaws.com/user-profile/${user.userImage}`}

//// property photos
//// src={`https://boxlty-media.s3.amazonaws.com/property-images/${value}`}

export const ToursMain: React.FC<{}> = (props: Props) => {
   const theme = useTheme();
   const { primaryButtonsGradient } = useGradient();
   const { classes } = useStyles();
   const { TourType } = useParams<{ TourType: string }>();
   const [touringAgreementErrorOpen, setTouringAgreementErrorOpen] =
      useState(false);
   const [typeValue, setTypeValue] = useState(TourType);
   const [selected, setSelected] = useState<GridRowSelectionModel>([]);
   const navigate = useNavigate();
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);
   const apiRef = useGridApiRef();
   const {
      tours,
      toursAll,
      error,
      loading,
      upcomingCount,
      todayCount,
      tomorrowCount,
      completedCount,
      allCount,
   } = useToursState();
   const dispatch = useToursDispatch();
   const formatter = new Intl.DateTimeFormat("en-US");
   const { user } = useUserState();
   function returnStandardTimeString(time: string) {
      let timeArray = time.split(":"); // convert to array

      // fetch
      var hours = Number(timeArray[0]);
      var minutes = Number(timeArray[1]);

      // calculate
      var timeValue;

      if (hours > 0 && hours <= 12) {
         timeValue = "" + hours;
      } else if (hours > 12) {
         timeValue = "" + (hours - 12);
      } else if (hours === 0) {
         timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

      return timeValue;
   }

   const fetchTouringAgreementFilesForSelectedTours = async () => {
      const selectedRows = apiRef.current.getSelectedRows();
      let selectedTourIds: number[] = [];
      for (const [key, value] of selectedRows) {
         if (value.touringAgreement !== 1) {
            handleExportMenuClose();
            setTouringAgreementErrorOpen(true);
            return;
         } else {
            selectedTourIds.push(value.bookingId);
         }
      }

      await fetchTouringAgreementFilesWithBookingIdArray({
         bookingIds: selectedTourIds,
      }).then((response) => {
         let res: any = response as any;
         if (response && response.data && res.status === 200) {
            const b64 = response.data.data;
            const blob = b64ToBlob(b64, "application/zip");
            const url = window.URL.createObjectURL(blob);
            // uses the download attribute on a temporary anchor to trigger the browser
            // download behavior. if you need wider compatibility, you can replace this
            // part with a library such as filesaver.js
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "touring_agreements.zip");
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(url);
            handleExportMenuClose();
         }
      });
   };

   // Removed until Tour Details is set up 10/4
   // useEffect(() => {
   //     setTimeout(() => {

   //         let rows: NodeListOf<HTMLElement> = document.querySelectorAll('.MuiDataGrid-row');
   //         Array.from(rows).map((row, index) => {
   //             //adjust row styling here
   //             row.addEventListener('mouseover', (e) => {
   //                 let el: any = (e.target);
   //                 if (el.type == null || el.type != "checkbox") {
   //                     row.style.backgroundColor = 'lightgray';
   //                     row.style.cursor = 'pointer'
   //                 }
   //             })
   //             row.addEventListener('mouseout', (e) => {
   //                 let el: any = (e.target);
   //                 if (el.type == null || el.type != "checkbox") {
   //                     row.style.backgroundColor = 'initial';
   //                 }
   //             })
   //             let tourID = index;
   //             row.addEventListener('click', (e) => {
   //                 let el = (e.target as HTMLInputElement);
   //                 if (el.type != "checkbox") {
   //                     navigate(`/tours/${tourID}/details`)
   //                 }
   //             })
   //         });

   //
   // }, [])}, 100)

   const handleExportMenuClose = () => {
      setExportAnchorEl(null);
   };

   const handleExportMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      setExportAnchorEl(event.currentTarget);
   };

   /////Define Columns based on model returned by API
   const gridColumns: GridColDef[] = [
      {
         field: "visitor",
         headerName: "Visitor",
         width: 250,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "10px" }}
            >
               <Grid item display={"flex"} alignItems={"center"}>
                  <Avatar
                     src={`https://boxlty-media.s3.amazonaws.com/user-profile/${params.value.photo}`}
                     style={{ marginRight: "5px" }}
                  />
                  <span
                     style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        overflowX: "auto",
                        maxWidth: "175px",
                        letterSpacing: "0.45px",
                     }}
                  >
                     {`${params.value.firstName} ${params.value.lastName}`}
                  </span>
               </Grid>
            </Grid>
         ),
         valueGetter: (params: GridValueGetterParams) => {
            return {
               photo: params.row.visitorProfile,
               firstName: params.row.visitorFirtName,
               lastName: params.row.visitorLastName,
            };
         },
         valueFormatter: ({ value }) => `${value.firstName} ${value.lastName}`,
      },
      {
         field: "status",
         headerName: "Status",
         width: 195,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item>
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {params.value}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            switch (params.row.bookingStatusIdNew) {
               case "2":
                  return "Scheduled";
               case "1":
                  return "Pending Reschedule";
               case "5":
                  return "In-Progress";
               case "4":
                  return "Cancelled";
               case "8":
                  return "Rescheduled";
               case "6":
                  return "Completed";
               case "3":
                  return "Declined";
               case "7":
                  return "Selfie in Review";
               default:
                  // console.log(params.row.bookingStatusIdNew)
                  return "";
            }
         },
      },
      {
         field: "listing",
         headerName: "Listing",
         width: 275,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid item display={"flex"} alignItems={"center"}>
                  <Avatar
                     variant="rounded"
                     src={`https://boxlty-media.s3.amazonaws.com/property-images/${params.value.featurePhoto}`}
                     style={{ marginRight: "10px" }}
                  />
                  <Grid display={"flex"} direction={"column"}>
                     <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                        {params.value.propertyName}
                     </span>
                     <span style={{ color: "#707070", fontSize: "16px" }}>
                        {params.value.address}
                     </span>
                  </Grid>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) =>
            `${value.propertyName}, ${value.address}`,
         valueGetter: (params: GridValueGetterParams) => {
            return {
               address: params.row.propertyAddress,
               propertyName: params.row.propertyName,
               featurePhoto: params.row.propertyFeaturePhoto,
            };
         },
      },
      {
         field: "time",
         headerName: "Time",
         width: 200,
         renderCell: (params) => (
            <Grid
               container
               direction={"row"}
               display="flex"
               sx={{ gap: "4px" }}
            >
               {/* this will be a .map for the photo property */}
               <Grid
                  item
                  style={{ textAlign: "left" }}
                  display={"flex"}
                  flexDirection={"column"}
               >
                  <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                     {format(new Date(`${params.row.bookingd}T00:00:00`), "P")}
                  </span>
                  <span style={{ fontSize: "16px", color: "#707070" }}>
                     {returnStandardTimeString(params.value.startTime)} -{" "}
                     {returnStandardTimeString(params.value.endTime)}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) =>
            `${format(new Date(value.date), "P")}, ${
               returnStandardTimeString(value.startTime) +
               " - " +
               returnStandardTimeString(value.endTime)
            }`,
         valueGetter: (params: GridValueGetterParams) => {
            return {
               date: params.row.bookingd,
               startTime: params.row.startTime,
               endTime: params.row.endTime,
            };
         },
      },
      ///// Changed to onRowClick property on data grid table
      {
         field: "price",
         headerName: "Price",
         width: 135,
         renderCell: (params) => (
            <Grid container direction="column" display={"flex"}>
               <span style={{ fontWeight: "normal", fontSize: "18px" }}>
                  {params.value}
               </span>
            </Grid>
         ),
         valueFormatter: ({ value }) => `${value}`,
         valueGetter: (params: GridValueGetterParams) => {
            return new Intl.NumberFormat("en-US", {
               style: "currency",
               currency: "USD",
            }).format(params.row.price);
         },
      },
      {
         field: "listed",
         headerName: "Listed",
         width: 148,
         renderCell: (params) => (
            <Grid container direction="column" display={"flex"}>
               <Grid
                  item
                  style={{ textAlign: "left" }}
                  display={"flex"}
                  flexDirection={"column"}
               >
                  <div
                     style={{
                        fontWeight: "normal",
                        fontSize: "18px",
                        whiteSpace: "normal", // Allow text to wrap
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Display an ellipsis (...) when text overflows
                        wordWrap: "break-word",
                        maxHeight: "800px", // Adjust this value based on your layout
                        maxWidth: "150px", // Adjust this value based on your layout
                     }}
                  >
                     {params.value.listedFirstName}{" "}
                     {params.value.listedLastName}
                  </div>
                  <span style={{ fontSize: "16px", color: "#707070" }}>
                     {new Date(params.value.listedDate).toLocaleDateString(
                        "en-US",
                        {
                           year: "numeric",
                           month: "2-digit",
                           day: "2-digit",
                        }
                     )}
                  </span>
               </Grid>
            </Grid>
         ),
         valueFormatter: ({ value }) => ``,
         valueGetter: (params: GridValueGetterParams) => {
            return {
               listedFirstName: params.row.createdFirstName,
               listedLastName: params.row.createdLastName,
               listedDate: params.row.createdDate,
            };
         },
      },
   ];

   return (
      <>
         <AppBody>
            <Grid
               container
               sx={{ height: "98%", width: "100%" }}
               className={classes.mainContainer}
               direction="column"
            >
               <Snackbar
                  open={touringAgreementErrorOpen}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  autoHideDuration={10000}
                  onClose={() => {
                     setTouringAgreementErrorOpen(false);
                  }}
               >
                  <Alert
                     severity="error"
                     onClose={() => {
                        setTouringAgreementErrorOpen(false);
                     }}
                  >
                     One or more of the selected tours do not have a touring
                     agreement.
                  </Alert>
               </Snackbar>
               <Grid item>
                  <Grid
                     container
                     direction={"row"}
                     display="flex"
                     alignItems={"center"}
                     justifyContent="space-between"
                  >
                     <Grid item>
                        <span className={classes.header}>Tours</span>
                     </Grid>
                     <Grid item sx={{ width: "20%" }}>
                        <ToursPageSearchInput items={tours} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item sx={{ height: "80%", width: "100%" }}>
                  <Grid
                     container
                     direction={"column"}
                     display="flex"
                     height={"100%"}
                     flexWrap={"nowrap"}
                  >
                     <Grid item sx={{ paddingBottom: "5px" }}>
                        <Grid
                           container
                           direction={"row"}
                           display={"flex"}
                           justifyContent="space-between"
                        >
                           <Grid item sx={{ marginLeft: "15px" }}>
                              <Tabs
                                 value={TourType}
                                 classes={{
                                    root: classes.tabsRoot,
                                    flexContainer: classes.tabsFlex,
                                    indicator: classes.tabsIndicator,
                                 }}
                              >
                                 <Tab
                                    label={`Upcoming (${upcomingCount})`}
                                    disableFocusRipple
                                    disableRipple
                                    value="upcoming"
                                    onClick={() => {
                                       navigate("/tours/upcoming");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={`Today (${todayCount})`}
                                    disableFocusRipple
                                    disableRipple
                                    value="today"
                                    onClick={() => {
                                       navigate("/tours/today");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={`Tomorrow (${tomorrowCount})`}
                                    disableFocusRipple
                                    disableRipple
                                    value="tomorrow"
                                    onClick={() => {
                                       navigate("/tours/tomorrow");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={`Completed (${completedCount})`}
                                    disableFocusRipple
                                    disableRipple
                                    value="completed"
                                    onClick={() => {
                                       navigate("/tours/completed");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                                 <Tab
                                    label={`All (${allCount})`}
                                    disableFocusRipple
                                    disableRipple
                                    value="all"
                                    onClick={() => {
                                       navigate("/tours/all");
                                    }}
                                    classes={{ root: classes.tabSingleRoot }}
                                 />
                              </Tabs>
                           </Grid>
                           <Grid item width="300px">
                              <Grid
                                 container
                                 direction="row"
                                 display={"flex"}
                                 sx={{
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    height: "100%",
                                    gap: "5px",
                                 }}
                              >
                                 <Grid item className={classes.buttonContainer}>
                                    <Button
                                       onClick={handleExportMenuClick}
                                       variant="contained"
                                       size="small"
                                       aria-haspopup="true"
                                       id="export"
                                       disabled={
                                          selected && selected.length > 0
                                             ? false
                                             : true
                                       }
                                       aria-expanded={
                                          exportOpen ? "true" : undefined
                                       }
                                       aria-controls={
                                          exportOpen ? "export-menu" : undefined
                                       }
                                       sx={{
                                          borderRadius: "26px",
                                          backgroundImage:
                                             selected && selected.length > 0
                                                ? `${primaryButtonsGradient}`
                                                : "none",
                                       }}
                                    >
                                       Export
                                    </Button>
                                    <Menu
                                       open={exportOpen}
                                       anchorEl={exportAnchorEl}
                                       onClose={handleExportMenuClose}
                                       anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                       }}
                                    >
                                       <MenuItem
                                          onClick={() => {
                                             apiRef.current.exportDataAsCsv();
                                          }}
                                       >
                                          Tours
                                       </MenuItem>
                                       {/* Maybe add in check to only show for realtor?!? Ryan S 12/16/24 */}
                                       {user &&
                                       user.isRealtor &&
                                       user.isRealtor === "1" ? (
                                          <MenuItem
                                             onClick={() => {
                                                fetchTouringAgreementFilesForSelectedTours();
                                             }}
                                          >
                                             Touring Agreements
                                          </MenuItem>
                                       ) : null}
                                    </Menu>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item display={"flex"} flex={1}>
                        <div
                           style={{
                              width: "100%",
                              overflowX: "auto",
                              overflowY: "auto",
                           }}
                        >
                           <DataTableComponent
                              loading={loading}
                              onRowClick={(params) => {
                                 navigate(
                                    `/tours/${params.row.bookingId}/details`
                                 );
                              }}
                              apiRef={apiRef}
                              getRowID={(params) => params.bookingId}
                              items={tours.length > 0 ? tours : []}
                              selected={selected}
                              setSelected={setSelected}
                              columns={gridColumns}
                           />
                        </div>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </AppBody>
      </>
   );
};

const useStyles = makeStyles({ name: "ListingsMain" })((theme) => ({
   mainContainer: {},
   firstRow: {},
   secondRow: {},
   header: {
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "38px",
      letterSpacing: ".25px",
      fontWeight: "bold",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      marginTop: "-15px",
      fontWeight: "bold",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      paddingBottom: "5px",
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "bold",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
   buttonContainer: {
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      position: "relative",
      bottom: "7px",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   listingsCellHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   listingsCellAddress: {
      color: "#707070",
      fontSize: "14px",
   },
   createdHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   createdDate: {
      color: "#707070",
      fontSize: "14px",
   },
   updatedHeader: {
      fontWeight: "bold",
      color: "black",
      fontSize: "16px",
   },
   updatedDate: {
      color: "#707070",
      fontSize: "14px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
}));
