import React from "react";
import AppBody from "../../components/body/main.body.component.jsx";
import {
   Alert,
   Avatar,
   CircularProgress,
   Grid,
   IconButton,
   Menu,
   MenuItem,
   Snackbar,
   Tab,
   Tabs,
   useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import RoundedButton from "../../components/common/boxlty.button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
   getTeamMemberDetails,
   getLenderTours,
} from "../../contexts/api/api.functions";
import { TeamMemberDetails } from "../teams/non-enterprise/teams.types.js";
import { ToursFilter1, ToursFilter2 } from "../../contexts/api/api.types";
import { MoreHoriz } from "@mui/icons-material";
import TabbedContainerWithSubTabs from "../../components/common/boxlty.tabbedcontainer.subtabs";
import { ReactComponent as LocksIconActive } from "../../styles/assets/Icons/tours-icon-active.svg";
import { ReactComponent as LocksIconInActive } from "../../styles/assets/Icons/tours-icon-grey.svg";

interface ToursTabDetails {
   bookingDate: string;
   startTime: string;
   endTime: string;
   propertyName: string;
   propertyAddress: string;
   propertyFeaturePhoto: string;
}

function LeadsDetailsPage() {
   const { classes } = useStyles();
   const theme = useTheme();
   const [loadingUser, setLoadingUser] = useState(true);
   const { UserId } = useParams<{ UserId: string }>();
   const { PartnerType } = useParams<{ PartnerType: string }>();
   const [userDetail, setUserDetail] = useState<TeamMemberDetails | null>(null);
   const [showActionsPopup, setShowActionsPopup] = useState(false);
   const [showDeletePopup, setShowDeletePopup] = useState(false);
   const [showSnackbar, setShowSnackBar] = useState(false);
   const [snackBarText] = useState("");
   const [snackBarSuccess] = useState<boolean>(true);
   const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
      null
   );
   const exportOpen = Boolean(exportAnchorEl);

   const [typeValue, setTypeValue] = useState("upcoming");
   const [upcomingTours, setUpcomingTours] = useState<Array<ToursTabDetails>>(
      []
   );
   const [completedTours, setCompletedTours] = useState<Array<ToursTabDetails>>(
      []
   );
   const [leadChannel, setLeadChannel] = useState("");

   function returnStandardTimeString(time: string) {
      let timeArray = time.split(":"); // convert to array

      // fetch
      var hours = Number(timeArray[0]);
      var minutes = Number(timeArray[1]);

      // calculate
      var timeValue;

      if (hours > 0 && hours <= 12) {
         timeValue = "" + hours;
      } else if (hours > 12) {
         timeValue = "" + (hours - 12);
      } else if (hours === 0) {
         timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

      return timeValue;
   }

   const fetchUserDetails = async () => {
      if (UserId) {
         const res = await getTeamMemberDetails(UserId);
         // console.log(res);
         if (res && res.data && res.data.status === true) {
            setUserDetail(res.data.result);
            setLoadingUser(false);
         }
      }
   };

   const getTourTabDetails = async () => {
      const dataAll1 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.UPCOMING,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res1 = await getLenderTours(dataAll1);
      //console.log("upcoming tours", res1);
      let data1 = res1.data.result.data;
      const tours1 = data1.filter(
         (tour: any) =>
            tour.partnerUserId.toString() === PartnerType &&
            tour.visitorId.toString() === UserId
      );
      //console.log("upcoming", tours1);
      setUpcomingTours(tours1);

      const dataAll4 = {
         filter1: ToursFilter1.BYDATE,
         filter2: ToursFilter2.PAST60DAYS,
         searchText: "",
         itemsPerPage: 500,
         page: 1,
      };
      const res2 = await getLenderTours(dataAll4);
      //console.log("all tours", res2);
      let data2 = res2.data.result.data;
      const tours2 = data2.filter(
         (tour: any) =>
            tour.partnerUserId.toString() === PartnerType &&
            tour.visitorId.toString() === UserId
      );
      //console.log("past", tours2);
      setCompletedTours(tours2);

      if (tours1.length > 0) {
         const channel =
            tours1[0].partnerFirstName + " " + tours1[0].partnerLastName;
         setLeadChannel(channel);
      } else {
         const channel =
            tours2[0].partnerFirstName + " " + tours2[0].partnerLastName;
         setLeadChannel(channel);
      }
   };

   useEffect(() => {
      fetchUserDetails();
      getTourTabDetails();
   }, [UserId]);
   // useEffect(() => {
   //   //console.log("set user details", userDetail);
   // }, [userDetail]);
   useEffect(() => {
      if (showActionsPopup) {
         const actionsContainer = document.querySelector("#actions-container");
         actionsContainer?.addEventListener("mouseleave", handlePopup);
      }
   }, [showActionsPopup]);
   // useEffect(() => {}, [showSnackbar, snackBarText]);
   // useEffect(() => {
   //   //console.log("new selected state ====>", selectedListing);
   // }, [selectedListing]);
   // useEffect(() => {
   // }, [actionsAnchorEl]);

   // useEffect(() => {
   //    const selectDropdown = document.getElementById("add-members-dropdown");
   //    addToListingMembers.forEach((item) => {
   //       const option = document.createElement("option");
   //       option.value = item.email; // Use userId as the value
   //       option.text = item.userName; // Display the name in the dropdown
   //       selectDropdown?.appendChild(option);
   //    });
   // }, [addToListingMembers]);

   // useEffect(() => {
   //    const selectDropdown = document.getElementById(
   //       "reassign-members-dropdown"
   //    );
   //    reassignListingMembers.forEach((item) => {
   //       const option = document.createElement("option");
   //       option.value = item.email; // Use userId as the value
   //       option.text = item.userName; // Display the name in the dropdown
   //       selectDropdown?.appendChild(option);
   //    });
   // }, [reassignListingMembers]);

   const handleDeleteMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      setExportAnchorEl(event.currentTarget);
   };

   const handleDeleteMenuClose = () => {
      setExportAnchorEl(null);
   };
   function handlePopup() {
      //console.log("popup function before", showActionsPopup);
      setShowActionsPopup(!showActionsPopup);
      ////console.log("popup function after", showActionsPopup);
   }
   const closeDeletePopup = () => {
      setShowDeletePopup(false);
   };
   const handleOpenDeletePopup = () => {
      setShowDeletePopup(true);
   };

   function formatPhoneNumber(phoneNumber: string) {
      // Remove any non-digit characters from the phone number
      const cleaned = phoneNumber.replace(/\D/g, "");

      // Check if the cleaned phone number has 10 or 11 digits
      const isValidNumber = /^[0-9]{10,11}$/.test(cleaned);

      if (isValidNumber) {
         if (cleaned.length === 10) {
            // Format as (xxx) xxx-xxxx for 10-digit numbers
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(
               3,
               6
            )}-${cleaned.slice(6)}`;
         } else if (cleaned.length === 11) {
            // Format as x(xxx) xxx-xxxx for 11-digit numbers
            return `${cleaned[0]}(${cleaned.slice(1, 4)}) ${cleaned.slice(
               4,
               7
            )}-${cleaned.slice(7)}`;
         }
      }

      // If the input is not a valid 10 or 11-digit number, return an error message or handle it as needed
      return "Invalid phone number";
   }

   return (
      <>
         <AppBody>
            <div className={classes.PageContainer}>
               {/* Header */}
               <h1 className={classes.PageHeader}>
                  {/* {props.listingName ? props.listingName : "Family Home"} */}
                  {loadingUser
                     ? ""
                     : userDetail?.userName
                     ? userDetail?.userName
                     : ""}
               </h1>
               <Snackbar
                  open={showSnackbar}
                  autoHideDuration={4000}
                  sx={{
                     zIndex: (theme) => theme.zIndex.drawer + 2,
                     position: "absolute",
                  }}
                  onClose={() => {
                     setShowSnackBar(false);
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               >
                  <Alert
                     onClose={() => {
                        setShowSnackBar(false);
                     }}
                     severity={snackBarSuccess ? "success" : "error"}
                     color={snackBarSuccess ? "success" : "error"}
                  >
                     {snackBarText}
                  </Alert>
               </Snackbar>
               {/* Split Container */}
               {loadingUser ? (
                  <CircularProgress />
               ) : (
                  <section className={classes.SplitContainer}>
                     {/* Main Section */}
                     <section className={classes.MainSection}>
                        <section className={classes.ThreeDots}>
                           <IconButton
                              edge="start"
                              aria-haspopup="true"
                              id="lock-delete"
                              aria-expanded={exportOpen ? "true" : undefined}
                              aria-controls={
                                 exportOpen ? "lock-delete-menu" : undefined
                              }
                              onClick={handleDeleteMenuClick}
                           >
                              <MoreHoriz fontSize="large" />
                           </IconButton>
                        </section>

                        {/* Top Row */}
                        {/* Visitor Information Section */}
                        <section className={classes.VisitorSectionContainer}>
                           {/* Visitor Info */}
                           <section className={classes.VisitorInfoSection}>
                              {/* Visitor IMG */}
                              <div className={classes.ProfileImageContainer}>
                                 {userDetail?.userProfile !== undefined &&
                                 userDetail.userProfile !== "" &&
                                 userDetail.status !== "1" ? (
                                    userDetail.userProfile !== null ? (
                                       <img
                                          className={classes.ProfileImage}
                                          src={`https://boxlty-media.s3.amazonaws.com/user-profile/${userDetail.userProfile}`}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    ) : (
                                       <Avatar
                                          className={classes.ProfileImage}
                                          style={{ borderRadius: "15px" }}
                                          alt="Avatar"
                                       />
                                    )
                                 ) : (
                                    <div
                                       style={{
                                          backgroundColor: "#707070",
                                          borderRadius: "15px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "white",
                                          fontWeight: "bold",
                                       }}
                                       className={classes.ProfileImage}
                                    >
                                       INVITATION SENT
                                    </div>
                                 )}
                              </div>
                              {/* Tour Info */}
                              <section className={classes.TeamMemberInfo1}>
                                 {/* Email */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    EMAIL
                                 </p>
                                 <p className={classes.VisitorInfoText2}>
                                    {userDetail?.email ? userDetail?.email : ""}
                                 </p>
                                 {/* Phone */}
                                 <p className={classes.VisitorInfoFieldLabel}>
                                    PHONE
                                 </p>
                                 <p className={classes.VisitorInfoText3}>
                                    {userDetail?.mobile
                                       ? formatPhoneNumber(userDetail?.mobile)
                                       : ""}
                                 </p>
                              </section>
                              <Menu
                                 className={classes.exportMenu}
                                 open={exportOpen}
                                 id="lock-delete-menu"
                                 anchorEl={exportAnchorEl}
                                 onClose={handleDeleteMenuClose}
                                 MenuListProps={{
                                    "aria-labelledby": "lock-delete",
                                 }}
                                 anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                 }}
                                 transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                 }}
                              >
                                 <MenuItem onClick={handleOpenDeletePopup}>
                                    Delete
                                 </MenuItem>
                              </Menu>
                              {showDeletePopup && (
                                 <div className={classes.ActionsPopupOverlay}>
                                    <div className={classes.PopupCentered}>
                                       {/* Popup content */}
                                       <p className={classes.PopupHeader}>
                                          DELETE LEAD
                                       </p>
                                       <p className={classes.PopupText}>
                                          Delete {userDetail?.userName}?
                                       </p>
                                       <div
                                          className={
                                             classes.PopupOptionsSection
                                          }
                                       >
                                          <RoundedButton
                                             text="CANCEL"
                                             listener={() => {
                                                closeDeletePopup();
                                                handleDeleteMenuClose();
                                             }}
                                             boldText={true}
                                          />
                                          <RoundedButton
                                             text="DELETE"
                                             listener={() => {
                                                //handleDelete();
                                                //handleDeleteMenuClose();
                                             }}
                                             color="primary"
                                             boldText={true}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </section>
                           <div className={classes.MiniDivider}></div>

                           {/* Scheduled section */}
                           <section className={classes.TeamMemberInfo1}>
                              {/* Phone */}
                              <p className={classes.VisitorInfoFieldLabel}>
                                 LEAD CHANNEL
                              </p>
                              <p className={classes.VisitorInfoText2}>
                                 {leadChannel}
                              </p>
                              {userDetail?.preApproved === 1 && (
                                 <>
                                    <p
                                       className={classes.VisitorInfoFieldLabel}
                                    >
                                       DOCUMENTS FOR DOWNLOAD
                                    </p>
                                    <p className={classes.VisitorInfoText2}>
                                       <a
                                          href={
                                             userDetail.preApprovalDocument !==
                                             ""
                                                ? `https://boxlty-media.s3.amazonaws.com/user-documents/${userDetail.preApprovalDocument}`
                                                : ""
                                          }
                                          style={{
                                             color: theme.palette.common
                                                .primary,
                                          }}
                                       >
                                          Pre-approval-doc.pdf
                                       </a>
                                    </p>
                                 </>
                              )}
                           </section>
                        </section>
                     </section>
                     <React.Fragment>
                        <section className={classes.SecondarySection}>
                           {/* Component to house those two sections */}
                           <TabbedContainerWithSubTabs
                              tabs={[
                                 {
                                    tabName: "Tours",
                                    tabIconActive: <LocksIconActive />,
                                    tabIconInActive: <LocksIconInActive />,
                                    tabComponent: (
                                       <div
                                          className={
                                             classes.TourDetailsOuterContainer
                                          }
                                       >
                                          <Grid
                                             container
                                             direction={"column"}
                                             display={"flex"}
                                             justifyContent="space-between"
                                          >
                                             <Grid item>
                                                <div
                                                   className={
                                                      classes.TourDetailsHeaderRow
                                                   }
                                                >
                                                   <h1
                                                      className={
                                                         classes.TourDetailsInfoHeader
                                                      }
                                                   >
                                                      Tours
                                                   </h1>
                                                   <Tabs
                                                      value={typeValue}
                                                      //onChange={handleTabChange}
                                                      classes={{
                                                         root: classes.tabsRoot,
                                                         flexContainer:
                                                            classes.tabsFlex,
                                                         indicator:
                                                            classes.tabsIndicator,
                                                      }}
                                                   >
                                                      <Tab
                                                         label={`Upcoming (${upcomingTours.length})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="upcoming"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "upcoming tab"
                                                            // );
                                                            setTypeValue(
                                                               "upcoming"
                                                            );
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                      <Tab
                                                         label={`Completed (${completedTours.length})`}
                                                         disableFocusRipple
                                                         disableRipple
                                                         value="completed"
                                                         onClick={() => {
                                                            // console.log(
                                                            //    "completed tab"
                                                            // );
                                                            setTypeValue(
                                                               "completed"
                                                            );
                                                         }}
                                                         classes={{
                                                            root: classes.tabSingleRoot,
                                                         }}
                                                      />
                                                   </Tabs>
                                                </div>
                                             </Grid>
                                          </Grid>
                                          <section
                                             className={
                                                classes.ListingsContainer
                                             }
                                          >
                                             {typeValue === "upcoming"
                                                ? upcomingTours.map((tour) => (
                                                     <div>
                                                        <div
                                                           className={
                                                              classes.ListingItemName
                                                           }
                                                           style={{
                                                              marginLeft:
                                                                 "15px",
                                                           }}
                                                        >
                                                           {tour.bookingDate} |{" "}
                                                           {returnStandardTimeString(
                                                              tour.startTime
                                                           )}{" "}
                                                           -{" "}
                                                           {returnStandardTimeString(
                                                              tour.endTime
                                                           )}
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingItem
                                                           }
                                                        >
                                                           <div>
                                                              <Avatar
                                                                 className={
                                                                    classes.imgPreview
                                                                 }
                                                                 variant="rounded"
                                                                 src={`https://boxlty-media.s3.amazonaws.com/property-images/${tour.propertyFeaturePhoto}`}
                                                                 sx={{
                                                                    width: "75px !important",
                                                                    height:
                                                                       "75px !important",
                                                                 }}
                                                              />
                                                           </div>
                                                           <div
                                                              className={
                                                                 classes.ListingDetails
                                                              }
                                                           >
                                                              <p
                                                                 className={
                                                                    classes.ListingItemName
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyName
                                                                 }
                                                              </p>
                                                              <p
                                                                 className={
                                                                    classes.ListingItemAddress
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyAddress
                                                                 }
                                                              </p>
                                                           </div>
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingSectionDividerLine
                                                           }
                                                        ></div>
                                                     </div>
                                                  ))
                                                : completedTours.map((tour) => (
                                                     <div>
                                                        <div
                                                           className={
                                                              classes.ListingItemName
                                                           }
                                                           style={{
                                                              marginLeft:
                                                                 "15px",
                                                           }}
                                                        >
                                                           {tour.bookingDate} |{" "}
                                                           {returnStandardTimeString(
                                                              tour.startTime
                                                           )}{" "}
                                                           -{" "}
                                                           {returnStandardTimeString(
                                                              tour.endTime
                                                           )}
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingItem
                                                           }
                                                        >
                                                           <div>
                                                              <Avatar
                                                                 className={
                                                                    classes.imgPreview
                                                                 }
                                                                 variant="rounded"
                                                                 src={`https://boxlty-media.s3.amazonaws.com/property-images/${tour.propertyFeaturePhoto}`}
                                                                 sx={{
                                                                    width: "75px !important",
                                                                    height:
                                                                       "75px !important",
                                                                 }}
                                                              />
                                                           </div>
                                                           <div
                                                              className={
                                                                 classes.ListingDetails
                                                              }
                                                           >
                                                              <p
                                                                 className={
                                                                    classes.ListingItemName
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyName
                                                                 }
                                                              </p>
                                                              <p
                                                                 className={
                                                                    classes.ListingItemAddress
                                                                 }
                                                              >
                                                                 {
                                                                    tour.propertyAddress
                                                                 }
                                                              </p>
                                                           </div>
                                                        </div>
                                                        <div
                                                           className={
                                                              classes.ListingSectionDividerLine
                                                           }
                                                        ></div>
                                                     </div>
                                                  ))}
                                          </section>
                                       </div>
                                    ),
                                 },
                              ]}
                              leftBorderRadius={"20px"}
                              showBoxShadow={true}
                              startingTabIndex={0}
                           />
                        </section>
                     </React.Fragment>

                     {/* Chat / Listing Details Section */}
                  </section>
               )}
            </div>
         </AppBody>
      </>
   );
}
const useStyles = makeStyles({ name: "TeamDetails" })((theme) => ({
   PageContainer: {
      display: "flex",
      flexDirection: "column",
   },
   PageHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      marginTop: "0px",
      marginBottom: "5px",
      height: "41.5px",
   },
   SplitContainer: {
      display: "flex",
      alignItems: "flex-start",
   },
   MainSection: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "10px",
      minWidth: "400px",
      width: "70%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      boxShadow: `0px 0px 10px 0px ${theme.palette.common.lightGray}`,
   },
   MainSectionTopRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "45px",
   },
   MainInfoSection: {
      display: "flex",
      alignItems: "flex-start",
   },
   TourImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   TourInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "5px",
      fontSize: "17px",
      fontFamily: theme.fonts.secondary.bold,
   },
   TourInfoText2: {
      margin: "0",
      fontWeight: "500",
      fontSize: "14px",
      marginBottom: "5px",
   },
   TourInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorSectionContainer: {
      marginTop: "25px",
   },
   VisitorSectionText: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "22px",
      marginBottom: "5px",
   },
   VisitorSectionDividerLine: {
      width: "250px",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
   },
   VisitorInfoSection: {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
   },
   VisitorImage: {
      borderRadius: "20px",
      height: "100px",
      width: "100px",
      objectFit: "fill",
      marginRight: "25px",
   },
   VisitorInfoText1: {
      fontWeight: "bold",
      margin: "0",
      marginBottom: "15px",
      fontFamily: theme.fonts.secondary.bold,
      fontSize: "17px",
      marginTop: "15px",
   },
   VisitorInfoText2: {
      margin: "0",
      fontWeight: "500",
      marginBottom: "15px",
      fontSize: "17px",
   },
   VisitorInfoText3: {
      fontWeight: "500",
      margin: "0",
   },
   VisitorInfoTextBold: {
      fontWeight: "bold",
      margin: "0",
      fontSize: "20px",
      marginBottom: "20px",
   },
   VisitorInfoFieldLabel: {
      fontSize: "14px",
      color: theme.palette.common.lightGray,
      margin: "0",
      marginTop: "10px",
      marginBottom: "3px",
   },
   ThreeDots: {
      cursor: "pointer",
      float: "right",
   },
   PreApprovalGayText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "5px",
      fontSize: "12px",
   },
   PreApprovalLink: {
      display: "block",
      color: theme.palette.common.primary,
      fontFamily: theme.fonts.secondary.med,
      fontSize: "13px",
      textDecoration: "underline",
      marginBottom: "50px",
   },
   MiniDivider: {
      height: "2px",
      minHeight: "2px",
      // boxShadow : `0px 0px 10px 2px ${theme.palette.common.lightGray}`,
      backgroundColor: "#BEBEBE",
      width: "100%",
      marginBottom: "25px",
      marginTop: "25px",
   },
   ScheduledText: {
      fontFamily: theme.fonts.secondary.med,
      color: theme.palette.common.lightGray,
      margin: "0",
      marginBottom: "1px",
      fontSize: "13px",
   },
   SecondarySection: {
      width: "40%",
      minHeight: "80vh",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginLeft: "auto",
      leftBorderRadius: "20px",
      showBoxShadow: "true",
   },
   ProfileImage: {
      height: "150px",
      width: "150px",
      objectFit: "cover",
   },
   ProfileImageContainer: {
      marginBottom: "5px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
      marginRight: "10px",
   },
   startIcon: {
      paddingBottom: "2px",
      justifySelf: "center",
   },
   TourDetailsHeaderRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
   },
   TourDetailsInfoHeader: {
      fontFamily: theme.fonts.secondary.bold,
      fontWeight: "bold",
      fontSize: "23px",
      whiteSpace: "nowrap",
      margin: "0px",
      marginRight: "15px",
      marginLeft: "15px",
   },
   TourDetailsOpenIcon: {
      height: "13px",
      width: "13px",
      cursor: "pointer",
   },
   TourDetailsOuterContainer: {
      paddingTop: "20px",
      paddingLeft: "20px",
      marginRight: "5px",
      minHeight: "80vh",
      maxHeight: "80vh",
      overflow: "hidden",
   },
   ListingSectionDividerLine: {
      width: "100%",
      maxHeight: "1px",
      minHeight: "1px",
      height: "1px",
      backgroundColor: theme.palette.common.darkGray,
      marginTop: "15px",
      marginBottom: "15px",
      marginLeft: "15px",
   },
   ActionsContainer: {
      marginLeft: "auto",
      fontSize: "30px",
      fontWeight: "bold",
      color: theme.palette.common.medGray,
      position: "relative",
      display: "inline-block",
      paddingBottom: "100px",
      paddingLeft: "125px",
   },
   ActionsButton: {
      cursor: "pointer",
      marginTop: "-25px",
   },
   ActionsPopup: {
      //display: "none",
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      borderRadius: "20px",
      boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
      minWidth: "125px",
      zIndex: 1,
      right: 0,
      top: "10px",
   },
   TeamMemberInfo1: {
      marginLeft: "10px",
   },
   ActionsText: {
      fontSize: "14px",
      textAlign: "left",
      paddingLeft: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      cursor: "pointer",
      "&:hover": {
         backgroundColor: theme.palette.common.lightGray,
         color: "#fff",
      },
   },
   ActionsPopupOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9998, // Adjust z-index as needed
   },
   PopupCentered: {
      top: "50%",
      left: "50%",
      //transform: "translate(-50%, -50%)",
      zIndex: 9999,
      minHeight: "200px",
      minWidth: "400px",
      backgroundColor: "white",
      borderRadius: "25px",
   },
   PopupHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "21px",
      fontFamily: theme.fonts.secondary.bold,
   },
   PopupText: {
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
   },
   PopupOptionsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtonsSection: {
      display: "flex",
      marginTop: "50px",
      justifyContent: "center",
      marginBottom: "25px",
   },
   PopupButtons: {
      margin: "0 5px",
   },
   ListingsContainer: {
      marginTop: "25px",
      marginRight: "40px",
   },
   ListingItem: {
      display: "flex",
      alignItems: "center",
   },
   ListingDetails: {
      flex: 1,
      paddingLeft: "10px",
      paddingRight: "10px",
   },
   exportMenu: {
      right: "100px",
      position: "absolute",
      textAlign: "center",
      padding: theme.spacing(0.1),
      display: "flex",
   },
   exportMenuItem: {
      justifySelf: "right",
      display: "flex",
   },
   actionContainer: {
      alignItems: "center",
      paddingTop: "2px",
   },
   imgPreview: {
      marginTop: "3px",
      marginLeft: "15px",
   },
   ListingItemName: {
      fontSize: "18px",
      font: theme.fonts.primary.reg,
      fontWeight: "bold",
   },
   ListingItemAddress: {
      fontSize: "14px",
      font: theme.fonts.secondary.reg,
      color: theme.palette.common.medGray,
   },
   AddMemberDropdown: {
      display: "flex",
      margin: "auto",
      width: "50%",
   },
   tabsRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      fontWeight: "bold",
      position: "relative",
      bottom: "10px",
   },
   tabsIndicator: {
      display: "flex",
      width: "2px",
   },
   tabSingleRoot: {
      fontFamily: theme.fonts.secondary.bold,
      display: "flex",
      justifyContent: "flex-end",
      //fontWeight: "bold",
      fontSize: "11px",
      position: "relative",
   },
   tabsFlex: {
      alignItems: "start",
      display: "flex",
      justifyContent: "flex-start",
   },
}));
export default LeadsDetailsPage;
